<template>
  <v-card>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title>{{pageTitle}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon to="/channels">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- start of toolbar 2 -->
    <v-toolbar color="secondary" flat dense>
      <v-btn
        v-if="pageMode == 'create'"
        class="mr-1"
        outlined
        color="toolbarIcon"
        text
        @click="sendData"
        :loading="loader"
      >
        <v-icon left dark>mdi-content-save</v-icon>Save
      </v-btn>
      <v-btn
        v-else
        class="mr-1"
        outlined
        color="toolbarIcon"
        text
        @click="sendData"
        :loading="loader"
      >
        <v-icon left dark>mdi-content-save</v-icon>Update
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="mr-1" outlined color="toolbarIcon" text to="/channels">
        <v-icon left dark>mdi-eye</v-icon>All Channels
      </v-btn>
    </v-toolbar>
    <!-- end of toolbar 2 -->

    <v-card-text>
      <v-row dense>
        <v-col cols="4">
          <v-text-field outlined dense v-model="channel.name" label="Channel Name"></v-text-field>

          <v-textarea
            class="mt-2"
            v-model="channel.descript"
            label="Description"
            auto-grow
            outlined
            document_lines="1"
            row-height="15"
          ></v-textarea>
        </v-col>

        <v-col cols="8" v-show="pageMode == 'view'">
          <v-tabs background-color="tabToolbar">
            <v-tab>Outlets</v-tab>
            <v-tab>Sales Reps</v-tab>
            <!-- v-tab-items -->

            <!-- outlets -->
            <v-tab-item>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field prepend-inner-icon="mdi-magnify" v-model="search" label="Search"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-data-table :headers="outletHeaders" :items="channel.bpartner" :search="search">
                    <template v-slot:item.CardName="{ item }">
                      <v-btn color="flatButton" text :to="`/outlet/${item.id}`">{{ item.CardName }}</v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- end of outlets -->

            <!-- Sales Rep -->
            <v-tab-item>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field prepend-inner-icon="mdi-magnify" v-model="search" label="Search"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-data-table :headers="oslpHeaders" :items="channel.employees" :search="search">
                    <template v-slot:item.SlpName="{ item }">
                      <v-btn color="flatButton" text :to="`/sales/employee/${item.id}`">{{ item.SlpName }}</v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- end of Sales Rep -->
          </v-tabs>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    initial: {
      type: Object,
    },
    pageMode: {
      type: String,
    },
    pageTitle: {
      type: String,
    },
  },
  data() {
    return {
      channel: {},
      search: null,
      loader: false,
      outletHeaders: [
        { text: "No", value: "CardCode" },
        { text: "Name", value: "CardName" },
        { text: "Region", value: "territory.descript" },
        { text: "Address", value: "Address" },
        { text: "Description", value: "description" },
      ],
      oslpHeaders: [
        { text: "No", value: "id" },
        { text: "Name", value: "SlpName" }
      ],
    };
  },
  watch: {
    initial: {
      handler: "setChannel",
      immediate: true,
    },
  },
  methods: {
    setChannel(val) {
      this.channel = { ...val };
    },
    sendData() {
      this.loader = true;
      this.$emit("data", this.channel);
    },
  },
};
</script>